import { merge } from 'lodash'
import { createStore } from 'vuex'
import { HOST, SERVER } from '../constants'

const map_storage = {
  token: 'token'
};
//

export default createStore({
  state: {
    token: sessionStorage.getItem(map_storage.token) || '',
    user_data: false
  },
  getters: {
    has_token: (state) => !!state.token,
    token: state => state.token,
    user_data: state => state.user_data
  },
  mutations: {
    set_data: (state, data) => state.user_data = data,
    set_token(state, token) {
      state.token = token;
      sessionStorage.setItem(map_storage.token, token);
    },
    clear_token(state) {
      state.token = '';
      state.user_data = {};
      sessionStorage.removeItem(map_storage.token);
    }
  },
  actions: {
    upload_file({ getters }, { name, file }) {
      const headers = {
        Authorization: `Token ${getters.token}`
      }
      let data = new FormData()
      data.append('name', name)
      data.append('image', file)
      return fetch(`${SERVER}images/`, {
        method: 'POST',
        mode: 'cors',
        headers,
        body: data
      })
    },
    req: ({ getters }, { url, full_url: full_url = false, data: data = null, method: method = 'GET', ...config }) => {
      const real_url = `${full_url ? HOST : SERVER}${url}`
      let headers = {
        'Content-Type': 'application/json'
      }
      if (getters.has_token) {
        headers['Authorization'] = `Token ${getters.token}`
      }
      let real_config = merge(config, {
        method,
        mode: 'cors',
        headers
      })
      if (method != 'GET' && data) {
        real_config['body'] = JSON.stringify(data)
      }
      return fetch(real_url, real_config)
    },
    login({ commit }, { login, password }) {
      let status_code = null;
      return fetch(
        `${HOST}/auth/token/login`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json'
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify({
          username: login,
          password: password
        })
      }).then(
        resp => {
          status_code = resp;
          return resp.json()
        }
      ).then(
        (data) => {
          if (status_code.ok) {
            commit('set_token', data.auth_token)
          }
          return {data, status: status_code.status, ok: status_code.ok}
        }
      )
    },
    logout({commit, dispatch}) {
      dispatch('req', {
        url: '/auth/token/logout',
        full_url: true,
        method: 'POST',
        data: {}
      }).then(r => {
        if (r.ok) {
          commit('clear_token')
        }
        return r.ok
      })
    },
    check_status: ({ dispatch, commit }) => {
      return dispatch('req', { full_url: true, url: '/auth/users/me/' }).then(
        r => {
          if (r.status == 200) {
            return r.json()
          }
          commit('clear_token')
          return Promise.resolve(false)
        }, () => {
          commit('clear_token')
          return false
        }
      ).then(
        r => {
          if (r) {
            commit('set_data', r);
          }
        }
      ).catch(
        () => {
          commit('clear_token')
          return false
        }
      )
    },
    request(_, { url, method: method = 'GET', headers: headers = {}, data: data = {}, ...config }) {
      const real_url = `${SERVER}${url}/`
      const real_data = JSON.stringify(data)
      return fetch(real_url, { method, headers, [method == 'GET' ? 'data' : 'body']: real_data, ...config }).then(
        resp => resp.json()
      )
    },
    send_claim({ dispatch }, data) {
      return dispatch(
        'request', {
        url: 'claim',
        body: JSON.stringify(data),
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json'
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      }).then(()=>true, ()=>false).then(r=>r)
    }
  },
  modules: {
  }
})
