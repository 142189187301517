// const SERVER = 'http://localhost:8089/api/v1/'

//const HOST = 'http://192.168.27.183:8089'

const HOST = process.env.NODE_ENV == 'development' ?'http://192.168.27.183:8089' : '';
const SERVER = `${HOST}/api/v1/`;

const LOGOUT_TIMEOUT = 60;
const TIMEOUT_DEBOUNCE = 100;

const FETCH_PLACES_TIMEOUT = 5 * 1000;
const FETCH_IMAGES_TIMEOUT = 7 * 1000;


export { SERVER, HOST, LOGOUT_TIMEOUT, TIMEOUT_DEBOUNCE, FETCH_IMAGES_TIMEOUT, FETCH_PLACES_TIMEOUT }
